(function () {
  'use strict';

  angular
    .module('discussions.index')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('dashboard.discussions.index', {
        url: '/index',
        templateUrl: 'dashboard/discussions/index/index.tpl.html',
        controller: 'DiscussionsIndexCtrl',
        controllerAs: 'discussionsIndex'
      });
  }
}());
